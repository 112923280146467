import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { Icon } from '../../../common/icon'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage10() {
  const question = {
    title: 'Frage 10 von 35',
    headline: '',
    subline:
      'Bitte ordne die nachfolgenden Arbeitsinhalte in deine persönliche Reihenfolge – dabei startest du mit deiner Lieblingstätigkeit:',
  }

  const answers = [
    {
      value: 'Administration & Dokumentation',
      id: 'administration',
    },
    {
      value: 'Spezielle Pflege (Verbandswechsel & Infusionen)',
      id: 'speziell',
    },
    {
      value: 'Beraten von Patienten & Anleiten von Angehörigen',
      id: 'beraten',
    },
    {
      value: 'Anleiten von Auszubildenden',
      id: 'anleiten',
    },
    {
      value: 'Allgemeine Pflege (Körperpflege, Essen reichen)',
      id: 'pflege',
    },
    {
      value: 'Handling von Medizintechnik',
      id: 'medezintechnik',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-purple">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="purple"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="sortable"
              answers={answers}
              color="yellow"
              index={10}
            />
          </div>
        </section>

        <MatchingNavigation
          color="purple"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-11`}
          prev={`${AREA_CARE_PATH}/spielen/gewichtung-1`}
          index={10}
        />

        <Icon position="3" name="sinus-curve-2" />
        <Icon position="4" name="pills" />
      </main>
    </>
  )
}
